import { facilitySlice } from './slice'
import { paginateProviderDomainRequest } from 'utils/apiUtils'

const { setFacilities, loadFacilities, reset } = facilitySlice.actions

export const fetchFacilityByServiceLinesAndZipCode = ({ zipcode, networks, serviceLines }: any) => async (dispatch: any) => {
  try {
    dispatch(loadFacilities())
    const results = await paginateProviderDomainRequest('/facility/opensearch/zipCode', {
      zipCode: zipcode,
      networks: networks?.join(',')?.toLowerCase(),
      serviceLines,
      distance: 100,
      allowUnpublished: true,
    })
  
    dispatch(setFacilities(results))
  } catch (error) {
    dispatch(setFacilities([]))
  }
}

export const fetchFacilitiesByTin = (tin: string | number) => async (dispatch: any) => {
  try {
    dispatch(loadFacilities())
  
    const results = await paginateProviderDomainRequest('/facility/fetch', {
      tin,
      pageSize: 1000,
      allowUnpublished: true,
    })
  
    dispatch(setFacilities(results))
  } catch (error) {
    dispatch(setFacilities([]))
  }
}

export const resetFacilities = () => async (dispatch: any) => {
  dispatch(reset())
}
